exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-australia-all-universities-tsx": () => import("./../../../src/pages/australia/allUniversities.tsx" /* webpackChunkName: "component---src-pages-australia-all-universities-tsx" */),
  "component---src-pages-australia-index-tsx": () => import("./../../../src/pages/australia/index.tsx" /* webpackChunkName: "component---src-pages-australia-index-tsx" */),
  "component---src-pages-canada-all-universities-tsx": () => import("./../../../src/pages/canada/allUniversities.tsx" /* webpackChunkName: "component---src-pages-canada-all-universities-tsx" */),
  "component---src-pages-canada-index-tsx": () => import("./../../../src/pages/canada/index.tsx" /* webpackChunkName: "component---src-pages-canada-index-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-courses-tsx": () => import("./../../../src/pages/courses.tsx" /* webpackChunkName: "component---src-pages-courses-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-malaysia-index-tsx": () => import("./../../../src/pages/malaysia/index.tsx" /* webpackChunkName: "component---src-pages-malaysia-index-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-uk-all-universities-tsx": () => import("./../../../src/pages/uk/allUniversities.tsx" /* webpackChunkName: "component---src-pages-uk-all-universities-tsx" */),
  "component---src-pages-uk-index-tsx": () => import("./../../../src/pages/uk/index.tsx" /* webpackChunkName: "component---src-pages-uk-index-tsx" */),
  "component---src-pages-usa-all-universities-tsx": () => import("./../../../src/pages/usa/allUniversities.tsx" /* webpackChunkName: "component---src-pages-usa-all-universities-tsx" */),
  "component---src-pages-usa-index-tsx": () => import("./../../../src/pages/usa/index.tsx" /* webpackChunkName: "component---src-pages-usa-index-tsx" */),
  "component---src-pages-visa-migration-tsx": () => import("./../../../src/pages/visa-migration.tsx" /* webpackChunkName: "component---src-pages-visa-migration-tsx" */)
}

