import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBtREOaYoRa5HdPpcqjZQGl7FbtkwH42ew",
  authDomain: "bridge-world-wide-8ff57.firebaseapp.com",
  projectId: "bridge-world-wide-8ff57",
  storageBucket: "bridge-world-wide-8ff57.firebasestorage.app",
  messagingSenderId: "521069448647",
  appId: "1:521069448647:web:ff9cd33f70721203b1dde2",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
